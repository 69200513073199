.show-menu-button {
    visibility: visible;
    position: relative;
}

.close-menu-button {
    visibility: hidden;
    position: absolute;
}

.custom-select {
    position: relative;
    width: auto;
    max-width: 100%;
    font-size: 1.15rem;
  }
  
  .select-button {
    width: 100%;
    height: 100%;
    font-size: 1.15rem;
    background-color: transparent;
    color: #fff;
    padding: 0.675em 1em;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .selected-value {
    text-align: left;
  }
  
  .arrow {
    margin-left: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #ffffff;
    transition: transform ease-in-out 0.3s;
  }
  
  .select-dropdown {
    position: absolute;
    bottom: -75%;
    list-style: none;
    width: 100%;
    background-color: #fff;
    border: 1px solid #6A6A6A;
    border-radius: 4px;
    margin-top: 10px;
    max-height: 200px;
    padding: 0;
    overflow-y: auto;
    transition: 0.5s ease;
  
    transform: scaleY(0);
    opacity: 0;
    visibility: hidden;
  }
  
  .select-dropdown li {
    position: relative;
    left: 0;
    cursor: pointer;
    border-bottom: 1px solid #6A6A6A;
  }

  .select-dropdown li:last-child {
    border-bottom: none !important;
  }
  
  .select-dropdown li label {
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .select-dropdown::-webkit-scrollbar {
    width: 7px;
  }
  .select-dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
  }
  
  .select-dropdown::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 25px;
  }
  
  .select-dropdown li:hover,
  .select-dropdown input:checked ~ label {
    background-color: #212121;
    color: #fff !important;
  }
  
  .select-dropdown input:focus ~ label {
    background-color: #212121;
    color: #fff !important;
  }
  
  .select-dropdown input[type="radio"] {
    position: absolute;
    left: 0;
    opacity: 0;
  }
  
  /* interactivity */
  
  .custom-select.active .arrow {
    transform: rotate(180deg);
  }
  
  .custom-select.active .select-dropdown {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
  }


@media screen and (max-width: 850px) {
    .header-phone-number {
        position: absolute;
        visibility: hidden;
        opacity: 0;
    }
}