.restaurant-page-hero-section-background {
    background: rgb(39,40,41);
    background: linear-gradient(180deg, rgba(39,40,41,1) 74%, rgba(39,40,41,0.3897934173669467) 88%, rgba(39,40,41,0) 100%);
}

.restaurant-page-hero-section-images-section {
    left: 0;
    top: 75% !important;
}

.restaurant-page-hero-section-images {
    height: 90%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.restaurant-page-hero-section-images:nth-child(1) {
    background-image: url('../../assets/img/restaurant-page/restaurant-page-hero-section-image-1.jpg');
}
.restaurant-page-hero-section-images:nth-child(2) {
    background-image: url('../../assets/img/restaurant-page/restaurant-page-hero-section-image-2.jpg');
}
.restaurant-page-hero-section-images:nth-child(3) {
    background-image: url('../../assets/img/restaurant-page/restaurant-page-hero-section-image-3.jpg');
}
.restaurant-page-hero-section-images:nth-child(4) {
    background-image: url('../../assets/img/restaurant-page/restaurant-page-hero-section-image-4.jpg');
}
.restaurant-page-hero-section-images:nth-child(5) {
    background-image: url('../../assets/img/restaurant-page/restaurant-page-hero-section-image-5.jpg');
}

.menu-page-link-button {
    position: relative;
    overflow: hidden;
    transition: .4s ease-in-out;
}

.menu-page-link-button::before {
    z-index: -1;
    content: ' ';
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    left: -100%;
    top: 50%;
    transform: translateY(-50%);
    background-color: #BF9974;
    transition: .3s ease-in-out;
}

.menu-page-link-button:hover:before {
    left: 0% !important;
}
.menu-page-link-button:hover {
    color: #f7f7f7 !important;
}

.restaurant-page-cuisine-section-container {
    min-height: 70vh;
}

@media screen and (max-width: 1500px) {
    .restaurant-page-cuisine-section-container {
        min-height: 60vh;
    }
}

@media screen and (max-width: 1275px ) {
    .restaurant-page-cuisine-section-container {
        min-height: 55vh;
    }
}

@media screen and (max-width: 1200px ) {
    .restaurant-page-hero-section-images-section {
        top: 60% !important;
    }
}

@media screen and (max-width: 992px ) {
    .restaurant-page-cuisine-section-container {
        min-height: 65vh;
    }
}

@media screen and (max-width: 745px ) {
    .restaurant-page-cuisine-section-container {
        min-height: 70vh;
    }
}

@media screen and (max-width: 576px) {
    .restaurant-page-hero-section-images {
        height: 70%;
    }

    .restaurant-page-hero-section-images-section {
        top: 75% !important;
    }
}