.footer-mobile {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
}

@media screen and (max-width: 992px) {
    .footer-mobile {
        height: 100%;
        position: relative;
        opacity: 1;
        visibility: visible;
        overflow: visible;
    }

    .footer-pc {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        width: 0 !important;
        overflow: hidden;
    }
}