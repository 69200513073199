.room-item-info-container:hover > .room-item-buttons-container {
    bottom: 0 !important;
    opacity: 1 !important;
}

.room-item-info-container:hover > .room-item-amenities-container {
    opacity: 0 !important;
}

.hero_section-background-image {
    background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('../../assets/img/room-hero-bg.jpg');
    background-size: cover;
    background-position: center, center;
    background-repeat: no-repeat;
    background-attachment:unset;
}

@media screen and (max-width: 1400px) {
    .hero-section-left-images-line {
        position: absolute !important;
        top: -100%;
        visibility: hidden;
        opacity: 0;
    }
}