.single-room-info-container {
    border-radius: 21px;
}

.single-room-title-container {
    box-shadow: 15px 15px 15px 1px rgba(0, 0, 0, .25);
    background: linear-gradient(#272829, #0e0e0e);
    border-radius: 21px;
    transition: .5s ease-in-out;
}
.single-room-title-container:hover {
    box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, .3);
}

.single-room-equipment-container {
    background-color: rgba(33, 33, 33, 0.03);
    border-radius: 21px;
}

.single-room-equipment-container-title {
    border-bottom: 2px solid #a4a4a4;
}

@media screen and (max-width: 450px) {
    .single-room-title-container {
        box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, .3);
    }
}