.menu-container {
    background-color: rgba(0, 0, 0, 0.3);
    width: 0;
    padding: 0;
    float: right;
}

.menu {
    /*width: 50vw;*/
    width: 0;
    background-color: #272829;
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 62% 100%);
    /*clip-path: polygon(28% 0, 100% 0, 100% 100%, 60% 100%, 28% 12%);*/
    filter: drop-shadow(-45px 0px 25px rgba(0, 0, 0, 1));
}

a[class*='menu-link'] {
    transform: translateX(100%);
}

@media screen and (max-width: 850px) {
    .menu {
        /*width: 50vw;*/
        width: 0;
        clip-path: polygon(5% 0, 100% 0, 100% 100%, 50% 100%);
        /*clip-path: polygon(28% 0, 100% 0, 100% 100%, 60% 100%, 28% 12%);*/
        filter: drop-shadow(-45px 0px 25px rgba(0, 0, 0, 1));
    }
}

@media screen and (max-width: 500px) {
    .menu {
        /*width: 50vw;*/
        width: 0;
        clip-path: polygon(0% 0, 100% 0, 100% 100%, 30% 100%);
        /*clip-path: polygon(28% 0, 100% 0, 100% 100%, 60% 100%, 28% 12%);*/
        filter: drop-shadow(-45px 0px 25px rgba(0, 0, 0, 1));
    }
}