.about-page-hero-image-section {
    height: 100% !important;
}

.about-page-vertical-hero-image {
    background-image: url('../../assets/img/about-hotel-page-hero-image-1.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.about-page-horizontal-upper-hero-image {
    height: 65%;
    background-image: url('../../assets/img/about-hotel-page-hero-image-2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.about-page-horizontal-lower-hero-image {
    height: 40%;
    background-image: url('../../assets/img/about-hotel-page-hero-image-3.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* SVG section styles */
.about-hotel-mobile-svg {
    width: auto;
    height: auto;
    min-width: 430px;
    min-height: 2415px;
}

.about-page-animated-svg-section-text-container {
    height: 52%;
    left: 20%;
}

.about-page-animated-svg-section-title-text {
    font-size: calc(15px + 2vw);
}

.about-page-animated-svg-section-description-text {
    font-size: calc(5px + 1vw);
}

/* Image windows styling */
.about-hotel-page-sectioned-images {
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.about-hotel-page-sectioned-images:nth-child(1) {
    background-image: url('../../assets/img/about-hotel-page-sectioned-images/about-hotel-page-window-image-1.jpg');
}
.about-hotel-page-sectioned-images:nth-child(2) {
    background-image: url('../../assets/img/about-hotel-page-sectioned-images/about-hotel-page-window-image-2.jpg');
}
.about-hotel-page-sectioned-images:nth-child(3) {
    background-image: url('../../assets/img/about-hotel-page-sectioned-images/about-hotel-page-window-image-3.jpg');
}
.about-hotel-page-sectioned-images:nth-child(4) {
    background-image: url('../../assets/img/about-hotel-page-sectioned-images/about-hotel-page-window-image-4.jpg');
}
.about-hotel-page-sectioned-images:nth-child(5) {
    background-image: url('../../assets/img/about-hotel-page-sectioned-images/about-hotel-page-window-image-5.jpg');
}


@media screen and (max-width: 1200px) {
    .about-page-hero-image-section {
        height: 60vh !important;
    }
}

@media screen and (max-width: 860px) {
    .about-page-animated-svg-section-text {
        background-color: #272829;
    }

    .about-page-animated-svg-section-text-container {
        height: 75%;
        left: 0;
        top: 10% !important;
    }

    .about-page-animated-svg-section-title-text {
        font-size: calc(20px + 2vw);
    }
    
    .about-page-animated-svg-section-description-text {
        font-size: calc(10px + 1vw);
    }
}

@media screen and (max-width: 600px) {
    .about-page-hero-image-section {
        height: 50vh !important;
    }
    .about-hotel-page-sectioned-images {
        height: 35vh;
    }

}

@media screen and (max-width: 576px) {
    .about-page-hero-image-section {
        height: 40vh !important;
    }

    .about-page-vertical-hero-image {
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }
}

@media screen and (max-width: 380px) {
    .about-page-horizontal-lower-hero-image {
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }
}