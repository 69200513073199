.rental-item-image-container {
    transition: .3s ease-in-out;
}

.active {
    opacity: 1;
}
.active-button {
    border-color: #fff !important;
    color: #fff !important;
    transition: 0.5s;
}

.phrase-container {
    position: relative;
}

@media screen and (max-width: 1200px) {
    .services-info-button {
        font-size: 14px !important;
    }
}

@media screen and (max-width: 1087px) {
    .services-info-button {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 1040px) {
    .services-info-button {
        font-size: 12px !important;
    }
}