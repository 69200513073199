@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

:root {
  --dark-color: #272829;
  --darker-color: #333333;
  --darker-skin-color: #FFBF80;
  --dark-skin-color: #BF9974;
  --light-skin-color: #DFA66E;
  --golden-skin-color: #E2A971;
}

::-moz-selection { /* Code for Firefox */
  color: #e5e5e5;
  background: #99D8D0;
}

::selection {
  color: #f7f7f7;
  background: #99D8D0;
}

@font-face {
  font-family: "Navarro";
  src: url(./static/fonts/navarro-regular.ttf);
}

@font-face {
  font-family: "MontExtraLight";
  src: url(./static/fonts/Mont-ExtraLight.ttf);
}
@font-face {
  font-family: "MontLight";
  src: url(./static/fonts/Mont-Light.ttf);
}
@font-face {
  font-family: "MontRegular";
  src: url(./static/fonts/Mont-Regular.ttf);
}
@font-face {
  font-family: "MontSemiBold";
  src: url(./static/fonts/Mont-SemiBold.ttf);
}
@font-face {
  font-family: "MontBold";
  src: url(./static/fonts/Mont-Bold.ttf);
}

@font-face {
  font-family: "GilroyBold";
  src: url(./static/fonts/Gilroy-Bold.ttf);
}
@font-face {
  font-family: "GilroyHeavy";
  src: url(./static/fonts/Gilroy-Heavy.ttf);
}
@font-face {
  font-family: "GilroyMedium";
  src: url(./static/fonts/Gilroy-Medium.ttf);
}
@font-face {
  font-family: "GilroyRegular";
  src: url(./static/fonts/Gilroy-Regular.ttf);
}
@font-face {
  font-family: "GilroyLight";
  src: url(./static/fonts/Gilroy-Light.ttf);
}

body {
  background-color: #F7F7F7;
}

a {
  text-decoration: none;
}

/* Height classes */
.h-65 {
  height: 65% !important;
}

.h-xxl-65 {
  height: 0%;
}

/*Custom margin classes*/
.mb-sm-5 {
  margin-bottom: 0;
}

/*Text config classes (Non Bootstrap analog)*/
.text-spacing-1 {
  letter-spacing: 1px;
}

.text-spacing-2 {
  letter-spacing: 2px;
}

.text-color-dark-skin {
  color: var(--light-skin-color);
}

.text-color-darker-skin {
  color: var(--darker-skin-color);
}

.text-dark-color {
  color: var(--dark-color);
}

.text-grey-color {
  color: #A4A4A4;
}

.text-dark-grey-color {
  color: #6A6A6A;
}

/*Font Classes*/
.font-cormorant-regular {
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  font-style: normal;
}

.font-alegreya-sans-regular {
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-alegreya-sans-medium {
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.font-alegreya-sans-bold {
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.font-libre-caslon {
  font-family: "Libre Caslon Display", serif;
  font-weight: 400;
  font-style: normal;
}

.font-montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.font-montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.poiret-one-regular {
  font-family: "Poiret One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-mont-extra-light {
  font-family: "MontExtraLight";
}
.font-mont-light {
  font-family: "MontLight";
}
.font-mont-regular {
  font-family: "MontRegular";
}
.font-mont-semibold {
  font-family: "MontSemiBold";
}
.font-mont-bold {
  font-family: "MontBold";
}

.font-gilroy-heavy {
  font-family: "GilroyHeavy";
}
.font-gilroy-bold {
  font-family: "GilroyBold";
}
.font-gilroy-medium {
  font-family: "GilroyMedium";
}
.font-gilroy-regular {
  font-family: "GilroyRegular";
}
.font-gilroy-light {
  font-family: "GilroyLight";
}

.font-navarro-regular {
  font-family: "Navarro";
}

/*Background Color Classes*/
.bg-color-dark {
  background-color: var(--dark-color);
}

.bg-color-darker {
  background-color: var(--darker-color);
}

.bg-color-light-skin {
  background-color: var(--dark-skin-color);
}

.bg-color-golden-skin {
  background-color: var(--golden-skin-color);
}

.bg-color-dark-skin {
  background-color: #151515;
}

.bg-color-dark-grey {
  background-color: #6A6A6A;
}

/*Button hover background changes*/
.buton-hover-skin {
  transition: .3s ease-in-out;
}
.buton-hover-skin:hover {
  background-color: #bb8754;
}

.button-hover-darker {
  transition: .3s ease-in-out;
}
.button-hover-darker:hover {
  background-color: #1e1e1e;
}

.button-hover-right-fill-dark {
  background: linear-gradient(to right, var(--dark-color) 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-in-out;
}
.button-hover-right-fill-dark:hover {
  background-position: left bottom;
  color: #fff !important;
}

.button-hover-right-fill-skin {
  background: linear-gradient(to right, var(--dark-skin-color) 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-in-out;
}
.button-hover-right-fill-skin:hover {
  background-position: left bottom;
}

/*Border Classes*/
.border-color-darker {
  border-color: var(--darker-color) !important;
}

.border-color-skin {
  border-color: var(--dark-skin-color) !important;
}

.border-color-dark-grey {
  border-color: #6A6A6A !important;
}

/*Title Text Sizes*/
.text-title-5 {
  font-size: calc(25px + 5vw);
}

.text-title-4 {
  font-size: calc(25px + 4vw);
}

.text-title-3 {
  font-size: calc(25px + 3vw);
}

.text-title-2-3 {
  font-size: calc(40px + 2vw);
}

.text-title-2 {
  font-size: calc(25px + 2vw);
}

.text-title-1 {
  font-size: calc(25px + 1vw);
}

.text-responsive-1 {
  font-size: calc(5px + 1vw);
}

.text-responsive-2 {
  font-size: calc(10px + 1vw);
}

.text-size-5-and-half {
  font-size: calc(1rem * 1.15);
}

.text-spread {
  text-align: justify;
  text-justify: inter-word;
}

.cursor-pointer {
  cursor: pointer !important;
}

/*Offset from header*/
.header-offset {
  margin-top: 15vh;
}

/*Scroll Styling*/
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--light-skin-color);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--dark-skin-color);
}

@media screen and (min-width: 1200px) {
  .h-xxl-65 {
    height: 65% !important;
  }
}

@media screen and (max-width: 992px) {
  .my-from-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .me-from-lg-0 {
    margin-right: 0 !important;
  }

  .ms-from-lg-0 {
    margin-left: 0 !important;
  }

  .py-from-lg-3 {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

@media screen and (max-width: 576px) {
  .mb-sm-5 {
    margin-bottom: 3em !important;
  }
}

@media screen and (max-width: 768px) {
  .text-responsive-1 {
    font-size: calc(10px + 1vw);
  }

  .text-responsive-2 {
    font-size: calc(13px + 1vw);
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .text-spread-md {
    text-align: justify;
    text-justify: inter-word;
  }

  .text-title-md-4 {
    font-size: calc(25px + 4vw);
  }

  .text-title-md-3 {
    font-size: calc(25px + 3vw);
  }
}