.react-datepicker-wrapper {
    width: 50%;
}
 
.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker__input-container > input {
    width: 100%;
}

.booking-form-search-button {
    overflow: hidden;
    transition: .5s linear;
}
.booking-form-search-button:hover {
    box-shadow:
        0px 3.3px 2.2px rgba(0, 0, 0, 0.039),
        0px 7.8px 5.3px rgba(0, 0, 0, 0.057),
        0px 14.8px 10px rgba(0, 0, 0, 0.07),
        0px 26.4px 17.9px rgba(0, 0, 0, 0.083),
        0px 49.3px 33.4px rgba(0, 0, 0, 0.101),
        0px 118px 80px rgba(0, 0, 0, 0.14)
    ;
}

.booking-form-search-button::before {
    content: ' ';
    display: block;
    width: 35%;
    height: 100%;
    background-image: linear-gradient(95deg, rgba(217, 217, 217, 0), rgba(217, 217, 217, 0.1), rgba(217, 217, 217, 0.71), rgba(217, 217, 217, 0.1), rgba(217, 217, 217, 0));
    background-size: cover;
    position: absolute;
    top: 50%;
    left: -10%;
    transform: translate(-50%, -50%);
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    transition: .5s ease-in-out;
}
.booking-form-search-button:hover:before {
    left: 110%;
}

.guest-information-submit-button {
    box-shadow:
        0px 1.3px 3.3px rgba(0, 0, 0, 0.011),
        0px 2.9px 7.3px rgba(0, 0, 0, 0.016),
        0px 4.9px 12.4px rgba(0, 0, 0, 0.02),
        0px 7.5px 19.2px rgba(0, 0, 0, 0.023),
        0px 11.1px 28.4px rgba(0, 0, 0, 0.027),
        0px 16.3px 41.8px rgba(0, 0, 0, 0.03),
        0px 24.4px 62.6px rgba(0, 0, 0, 0.034),
        0px 38.9px 99.8px rgba(0, 0, 0, 0.039),
        0px 73px 187px rgba(0, 0, 0, 0.05)
    ;
    transition: .5s linear;
}

.guest-information-submit-button:hover {
    box-shadow:
  3.8px 2.4px 7.4px rgba(0, 0, 0, 0.001),
  4.6px 3px 13px rgba(0, 0, 0, 0.004),
  5px 3.2px 16.9px rgba(0, 0, 0, 0.007),
  5.2px 3.3px 19.4px rgba(0, 0, 0, 0.012),
  5.3px 3.4px 20.6px rgba(0, 0, 0, 0.019),
  5.5px 3.5px 20.7px rgba(0, 0, 0, 0.027),
  5.9px 3.8px 20px rgba(0, 0, 0, 0.038),
  6.6px 4.2px 18.8px rgba(0, 0, 0, 0.051),
  8.1px 5.2px 18px rgba(0, 0, 0, 0.068),
  14px 9px 21px rgba(0, 0, 0, 0.1)
;
}