#z-letter {
    stroke-dasharray: 200%;
    stroke-dashoffset: 200%;
}

#o-letter {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
}

#a-letter-one {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
}

#a-letter-two {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
}

#mountains {
    stroke-dasharray: 300%;
    stroke-dashoffset: 300%;
}

.z-10 {
    z-index: 10 !important;
}


@media screen and (max-width: 570px) {
    #oaza-logo-stroked {
        width: 169px !important;
        height: 127px !important;
    }
}