/*Menu Categories Page*/
.menu-categories-page-hero-section {
    background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../../assets/img/menu-page/menu-background-image.jpg');
    background-position: 0% 70%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 65vh;
}

.menu-categories-page-category-container {
    transform: translateY(-100px);
    border-radius: 62px;
    box-shadow:
        0px 0.2px 1.3px rgba(0, 0, 0, 0.044),
        0px 0.5px 3.1px rgba(0, 0, 0, 0.064),
        0px 0.9px 5.8px rgba(0, 0, 0, 0.079),
        0px 1.6px 10.3px rgba(0, 0, 0, 0.094),
        0px 2.9px 19.2px rgba(0, 0, 0, 0.114),
        0px 7px 46px rgba(0, 0, 0, 0.15)
    ;
}

.menu-categories-page-category-item {
    border-color: #ACB1B6;
    border-radius: 38px;
    padding-top: calc(2rem + 1vw);
    padding-bottom: calc(2rem + 1vw);
}

.menu-categories-page-category-item-text {
    font-size: 2.2rem !important;
}

.menu-categories-page-category-item-arrow {
    transition: .3s ease-in-out;
}

.menu-categories-page-category-item:hover > .menu-categories-page-category-item-arrow {
    transform: translateX(10px);
}

@media screen and (max-width: 768px) {
    .menu-categories-page-category-item-text {
        font-size: 1.5rem !important;
    }

    .menu-categories-page-hero-section {
        background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../../assets/img/menu-page/menu-background-image.jpg');
        background-position: 0% 70%;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 35vh;
    }

    .menu-categories-page-category-container {
        border-radius: 24px;
        padding-bottom: calc(1rem) !important;
    }

    .menu-categories-page-category-item {
        border-color: #ACB1B6;
        border-radius: 16px;
        padding-top: calc(4vw);
        padding-bottom: calc(4vw);
        margin-top: 1.4rem !important;
        margin-bottom: 1.4rem !important;
    }

    .menu-categories-page-category-container-heading-text-container {
        padding-bottom: calc(2vw) !important;
        margin-top: 0.5rem !important;
        margin-bottom: 0rem !important;
    }

    .menu-categories-page-category-container-heading-text {
        margin-left: calc(1rem + 1vw) !important;
    }

    .menu-categories-page-category-item > h1 {
        margin-left: calc(1rem + 1vw) !important;
    }

    .menu-categories-page-category-item-arrow {
        height: 20px;
        width: 10px;
        margin-right: calc(1rem + 1vw) !important;
    }

}


/*Main Menu Page With Content*/
/*Quick class for lighter secondary border*/
.border-secondary-light {
    border-color: #ACB1B6 !important;
}

.main-menu-page-category-sections-container-hidden {
    height: 0px !important;
    overflow: hidden;
}

.menu-content-page-categories-and-subcategories-container {
    position: sticky;
    top: 5%;
}


.menu-content-page-subcategories-mobile-container::-webkit-scrollbar {
    width: 0px !important;
    height: 0 !important;
}

@media screen and (max-width: 768px) {
    .menu-content-page-categories-and-subcategories-container-mobile {
        position: sticky !important;
        top: 0% !important;
        background-color: #f7f7f7;
    }
}

/*Classes for subcategories navigation*/
.inactive-subcategory {
    color: #212121;
    background-color: transparent;
    transition: .2s ease-in-out;
}

.active-subcategory {
    color: #F7F7F7;
    background-color: #4A4A4A;
    transition: .2s ease-in-out;
}

/*Menu Selected Items*/
.menu-page-selected-items-container::-webkit-scrollbar-thumb {
    background-color: #ACB1B6 !important;
}


@media screen and (max-width: 300px) {
    .header-galaxy-fold-flex-properties {
        flex-direction: column;
        justify-content: center !important;
    }

    .col-xsm-4 {
        flex: 0 0 auto !important;
    width: 33.33333333% !important;
    }

    .col-xsm-12 {
        flex: 0 0 auto !important;
        width: 100% !important;
    }
}