.hero-section-background-image {
    background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('../../assets/img/home_page_hero_section.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 100%;
    background-size: cover;
}

@media screen and (max-width: 600px) {
    .hero-section-background-image {
        background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('../../assets/img/home_page_hero_section.jpg');
        background-size: cover;
        background-position: center, center;
        background-repeat: no-repeat;
        background-attachment:unset;
    }
}